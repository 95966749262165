import { rgbToHex } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";


export default makeStyles ((theme) =>({
portfolioHeader: {
  
    color: 'yellowgreen',
    marginTop: '45px',
    marginBottom: '45px',
   
    fontFamily: 'Nosifer',
   
   
},

portfolioHeaderMobile: {
  
    color: 'yellowgreen',
    marginTop: '25px',
    marginBottom: '25px',
   
    fontFamily: 'Nosifer',
   
   
},

box: {
    backgroundColor: 'yellowgreen' ,
    
    padding: '5px',

   
  

},

twitterButton: {
    color: 'yellowgreen',    
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.9,
    backgroundColor: 'black',
    padding: '10px',
    border: 'solid 4px',
    borderColor: 'yellowgreen',
    margin: '8px'
},

tabHeader: {
    color: 'rgb(248,0,89)',
    fontWeight: 'bolder',
    padding: '10px',
    backgroundColor: 'black',
    margin: '5px'
   
},

githubHeader: {
color: 'yellowgreen',    
paddingTop: '10px',
paddingBottom: '10px',
fontFamily: 'Nosifer'
},

skillHeader: {
    color: 'yellowgreen',    
    paddingTop: '10px',
    paddingBottom: '10px',
    fontFamily: 'Nosifer',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.9,
    backgroundColor: 'black',
    },

githubButton: {
    color: 'yellowgreen',    
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.9,
    backgroundColor: 'black',
    padding: '10px',
    border: 'solid 4px',
    borderColor: 'yellowgreen'
   
    },

githubExamples: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.9,
    backgroundColor: 'black',
    margin: '5px'
},

tabInfo: {
    color: 'yellowgreen',
   padding: '10px',
    backgroundColor: 'black',
    
    fontWeight: 'bolder'
},

skills :{
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.7,
    backgroundColor: 'black',
    margin: '5px'
},

tabs:{
fontFamily: 'Nosifer',
color: 'rgb(248,0,89)',
top: 0,
bottom: 0,
left: 0,
right: 0,
opacity: 0.9,
backgroundColor: 'black',

},

tabsMobile:{
    fontFamily: 'Nosifer',
   
    
    },

font: {
    fontFamily: 'Nosifer'
},
   appBar: {
backgroundColor: 'rgb(248,0,89)'
   },
    icon:{
        color: "#00DEC1"
    },

    primary :{
        color: '#00DEC1'
      },
     secondary: {
       color: "#EC4EE2"
     },

     drawer: {
         backgroundColor: 'black'
     },

     layout: {
        width: 'auto',
        marginLeft: '30px',
        marginRight: '30px',
      
      
    },

    resume: {
        textDecoration: 'none',
        color: 'rgb(248,0,89)',
        fontFamily: 'Anton',
        fontSize: '30px',
        
    }
,
resumeCard: {
    backgroundColor: 'black',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.9,

}
})
)
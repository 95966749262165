import { makeStyles } from "@material-ui/core";
import portfolioMobile from '../assets/portfolioMobileBg.png';
import blackSmoke from '../assets/blackSmoke.png';
import portfolioBg from '../assets/portfolioBg.png';
import cyberBanner from '../assets/backgroundImage.jpg';
import contactBg from '../assets/contactBg.png';
import contactMobile from '../assets/mobileContactBg.png';

export default makeStyles ((theme) =>({

cardOne: {
  
backgroundImage:  `url(${portfolioBg})`,
backgroundSize: '100%',
height: '860px',

backgroundRepeat: 'no-repeat',



},

cardTwo: {
    backgroundImage:  `url(${cyberBanner})`,
    
    backgroundRepeat: 'no-repeat',
},
cardThree: {
    backgroundImage:  `url(${contactBg})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',  
},

portfolioMobileBg: {
    backgroundImage:  `url(${portfolioMobile})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',  
},

contactMobileBg : {
    backgroundImage:  `url(${contactMobile})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat', 
},

avatar: {
    marginTop: '200px',
    marginBottom: '90px' ,
    marginLeft: '10px', 
    marginRight: '150px', 
    height: '400px', 
    
   
    paddingTop: '20px',
    width: 'auto'
},

avatarMobile: {
    marginTop: '50px',
    marginBottom: '90px' ,
    marginLeft: '10px', 
    marginRight: '10px', 
    height: '300px', 
    
   
    paddingTop: '20px',
    width: 'auto'
},

aboutBg: {
    backgroundImage:  `url(${blackSmoke})`,
    backgroundSize: '100%',
    height: '500px',
    backgroundRepeat: 'no-repeat',  
},
font2:{
    fontFamily: 'Anton',
    marginBottom:'10px',
},
font: {
    fontFamily: 'Nosifer',
    color: 'yellowgreen'
},
    link:{
        textDecoration:"none",
        color: "#00DEC1",
        fontSize: "20px",
    },
   
background: {
backgroundColor: 'rgb(248,0,89)',
padding: '5px'
},

bioBgMobile: {
    backgroundColor: 'black',
    color: 'yellowgreen',
    padding: '5px',
    
  
},
bioBg: {
    backgroundColor: 'black',
    color: 'yellowgreen',
    padding: '5px',
    margin: '100px',
  
  
},

     drawer: {
         backgroundColor: 'black'
     },
skillsLayout: {


        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing (10),
        marginBottom: theme.spacing (15),
        

        // [theme.breakpoints.up(1100 + theme.spacing(3))]: {
        //     width: 1100,
        //     marginLeft: 'auto',
        //     marginRight: 'auto',
        // },

   

},
     layout: {
        width: 'auto',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '50px',
      
    },

    button: {
        border: 'solid 2px',
        borderColor: 'white',
        margin: '30px'
      
       

    },

    messageButton: {
        border: 'solid 3px',
        borderColor: 'rgb(248,0,89)',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.7,
        backgroundColor: 'black'
    },

    portfolioHeader: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.6,
        color: 'yellowgreen',
     
      
        fontFamily: 'Nosifer',
        
        marginTop: '50px'
    },

    banner :{
        marginTop: '-40px'
    },

    fontService : {
        fontFamily: 'Anton',
        marginBottom: '60px',
        marginRight: '500px',
        marginLeft: '500px',
        color: 'black',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.8,
        backgroundColor: 'lightblue',
    },

    fontServiceMobile : {
        fontFamily: 'Anton',
        marginBottom: '60px',
        marginRight: '50px',
        marginLeft: '50px',
        color: 'lightblue',
   
    },

    icon: {
        color: 'yellowgreen',
        backgroundColor: 'black',
        fontSize: 'small'

    }

})
)
import * as React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab, Typography, Box } from '@material-ui/core';

import Skills from './Skills';
import Github from './Github';
import Resume from './Resume';
import TwitterTab from './Twitter';

import useStyles from './PortfolioStyles';


function TabPanel(props) {
let classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
      
        <Box  align='center' sx={{ padding: '50px',}}>
          <Typography >{children}</Typography>
        </Box>
       
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Portfolio() {
  const [value, setValue] = React.useState(0);
let classes = useStyles(); 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
   
  
    
    <Box
      style={{  marginLeft: '100px', marginRight: '100px'}}
    >
        
        <Typography  align='center' variant='h2' className={classes.portfolioHeader}>Portfolio</Typography>
        
      <Tabs
     
       
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab className={classes.tabs} label="Skills" {...a11yProps(0)} />
        <Tab className={classes.tabs} label="Github" {...a11yProps(1)} />
        <Tab  className={classes.tabs} label="Resume" {...a11yProps(2)} />
        <Tab className={classes.tabs} label="Twitter" {...a11yProps(3)} />
        
      </Tabs>
      <TabPanel value={value} index={0}>
        <Skills />
      </TabPanel>
      <TabPanel value={value} index={1}>
       <Github />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Resume />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TwitterTab />
      </TabPanel>
   
    </Box>
  );
}

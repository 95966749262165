import * as React from 'react';
import {AppBar, Box, Toolbar, Typography, Button, IconButton, Card, useTheme, useMediaQuery} from '@material-ui/core';
import {Menu} from '@material-ui/icons';
import DrawerComponent from './Drawer';

import github from '../../assets/github.png';
import twitter from '../../assets/twitter.png';
import message from '../../assets/message.png';
import linkedIn from '../../assets/linkedIn.png';
import upwork from '../../assets/upwork.png';
import useStyles from './NavBarStyles';
import avatarPfp from '../../assets/avatarPfp.PNG';


const NavBar =()=> {
  let classes=useStyles(); 
  let theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
   
    <AppBar position="sticky" className={classes.appBar} >
          <Toolbar align='center'>
            <Button>
              <img src={avatarPfp} alt='avatar of shan' height='60px' />
            </Button>
            <DrawerComponent />
         
            <div className={classes.grow} />
            {isMobile ? (
              null
          
            ) :(
          <div align='center'>
            
          <Button href='https://github.com/shkramer1' target='_blank'>
<img src={github} alt='github link' height='60px' />
          </Button>

          <Button href='https://twitter.com/shancodes' target='_blank'>
<img src={twitter} alt='twitter link' height='60px' />
          </Button>

          <Button onClick={(e) => {e.preventDefault(); window.location.replace('/#contact');}}>
<img src={message} alt='contact link' height='60px' />
          </Button>
          
          <Button href='https://www.linkedin.com/in/shannon-kramer-747966b5/' target='_blank'>
<img src={linkedIn} alt='linkedin link' height='60px' />
          </Button>

          <Button href='https://www.upwork.com/freelancers/~0157c5fe41221f8983?viewMode=1' target='_blank'>
<img src={upwork} alt='upwork link' height='60px' />
          </Button>
           </div>
       )}
         
        </Toolbar>
      </AppBar>
   
  );
}

export default NavBar; 
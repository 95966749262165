import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Typography,
  CardMedia
} from "@material-ui/core";
import { Menu, LinkedIn, Instagram, Twitter, GitHub, FavoriteBorder } from "@material-ui/icons";
import { Link } from "react-router-dom";

import logo from '../../assets/avatarPfp.PNG';
import useStyles from './DrawerStyle'; 


function DrawerComponent() {
let classes = useStyles(); 
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
    <main >
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        
      >
          
        <List className={classes.drawer}>
           <ListItem align='center' onClick={() => setOpenDrawer(false)}>
        
            <ListItemText  align='center'>
              <Typography  className={classes.drawerHeader} variant='h5'>Shan Codes</Typography> 
              <FavoriteBorder align='center' className={classes.icon}  />
            </ListItemText>
          </ListItem>  
         
         
         <ListItem  onClick={() => setOpenDrawer(false)}>
            <ListItemText  >
              <Button  to="/"  className={classes.drawerLink} >
              Home</Button>
            </ListItemText>
          </ListItem> 
         
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Button className={classes.drawerLink} onClick={(e) => {e.preventDefault(); window.location.replace('/#about');}} >About</Button>
            </ListItemText>
          </ListItem>
          
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Button className={classes.drawerLink} onClick={(e) => {e.preventDefault(); window.location.replace('/#portfolio');}} >Portfolio</Button>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Button className={classes.drawerLink} onClick={(e) => {e.preventDefault(); window.location.replace('/#services');}} >Services</Button>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Button className={classes.drawerLink} onClick={(e) => {e.preventDefault(); window.location.replace('/#contact');}}>Contact</Button>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Button className={classes.drawerLink} target='_blank' component='a' href='https://linktr.ee/shancodes' >External Links</Button>
            </ListItemText>
          </ListItem>
          <Divider style={{backgroundColor: ' rgb(248,0,89)'}} />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography variant= 'h5' className={classes.drawerHeader} >Projects</Typography>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Button className={classes.portfolioLink}  target='_blank' component='a' href='' >Contact Form using Emailjs</Button>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Button className={classes.portfolioLink} component ='a' href='' target='_blank'>Drawer Component for Mobile Usability</Button>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Button className={classes.portfolioLink} component ='a' href='' target='_blank'>3D Cubes using React-Three-Fiber</Button>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Button className={classes.portfolioLink} component ='a' href='https://www.zonesix.io/' target='_blank'>Company Site by ShanCodes</Button>
            </ListItemText>
          </ListItem>
       
        <ListItem  onClick={() => setOpenDrawer(false)}>
            <ListItemText align='center'> 
              <Button className={classes.drawerLink} component ='a' href='https://github.com/shkramer1' target='_blank'>
              <GitHub style={{color: ' rgb(248,0,89)' ,margin: '4px'}}/>
            </Button>
            <Button className={classes.drawerLink} component ='a' href='https://www.linkedin.com/in/shannon-kramer-747966b5/' target='_blank'>
             <LinkedIn style={{color: ' rgb(248,0,89)', margin: '4px'}}/>
            </Button>
            <Button className={classes.drawerLink} component ='a' href='https://twitter.com/shancodes' target='_blank'>
             <Twitter style={{color: ' rgb(248,0,89)', margin: '4px'}} /> 
            </Button>
           
             
            
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
  
      <IconButton  onClick={() => setOpenDrawer(!openDrawer)}>
        <Menu className={classes.secondary} />
      </IconButton>
      </main>
    </>
  );
}
export default DrawerComponent;

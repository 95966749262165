import * as React from 'react';
import {useTheme,  Tabs, Tab, Typography, Box, Grid, useMediaQuery} from '@material-ui/core';
import PropTypes from 'prop-types';

import Skills from './Skills';
import Github from './Github';
import TwitterTab from './Twitter';
import Resume from './Resume';

import useStyles from './PortfolioStyles';


function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



export default function PortfolioMobile() {
  const theme = useTheme();
  let classes=useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
      <>
<Grid container align='center' >
<Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography   variant='h4'  className={classes.portfolioHeaderMobile}>Portfolio</Typography>
    </Grid>
    
    <Grid  item xs={12} sm={12} md={12} lg={12}>
    <Box  align='center' >
      <Box   className={classes.box}>
        
            <Tabs 
            
          variant='scrollable'
            value={value} 
            onChange={handleChange}
            scrollButtons='on'
            
             aria-label="basic tabs example">
              <Tab className={classes.tabsMobile}   label="Skills" {...a11yProps(0)} />
              <Tab className={classes.tabsMobile}  label="Github" {...a11yProps(1)} />
                 
               <Tab className={classes.tabsMobile} label="Resume" {...a11yProps(2)} />
              <Tab className={classes.tabsMobile} label="Twitter" {...a11yProps(3)} />
            
              
             
            </Tabs>
      
      
      </Box>
      <TabPanel value={value} index={0}>
        <Skills />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Github />
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Resume />
      </TabPanel>
      <TabPanel value={value} index={3}>
         <TwitterTab />
      </TabPanel>
      
     
    </Box>
    </Grid>
    </Grid>
    </>
  );
}

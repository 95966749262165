import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({

    input: {
        
        color: '#EC4EE2',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.8,
        backgroundColor: 'white',
   

    },
    text: {
        color: 'yellowgreen',
        fontFamily: 'Nosifer'
    },

    textarea: {
        height: '10em',
        top: 0,
bottom: 0,
left: 0,
right: 0,
opacity: 0.8,
backgroundColor: 'white',

      
        

    },

    wordCount: {
     

        margin: 'auto',
        talign: 'left',
        color: 'black',
        marginTop: theme.spacing(3)
    },

    statusMessage: {
        opacity: 0
    },
    success: {
        opacity: 1,
        color: 'green'
    },

    failure: {
        opacity: 1,
        color: 'red'
    },

    background: {
     
        backgroundColor: 'white'
    },

    select: {
        marginTop: theme.spacing(3)
    },

    layout: {

        width: 'auto',
       margin: theme.spacing(5),
    

        [theme.breakpoints.up(1100 + theme.spacing(3))]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },

    },

    button: {
        backgroundColor: 'black',
        color: 'yellowgreen',
        fontFamily: 'Nosifer',
      
        
        


    },

    border: {
        border: 'solid 15px',
        borderColor: 'black',
        backgroundColor: 'black'
    },

    logo: {
        height: '20%',
        width: '20%',
        justifyContent: 'center',
        objectFit: 'cover'

    },

    toolbar: theme.mixins.toolbar,

    contactInfo :{
      marginLeft: '30px',
      padding: '20px',
        backgroundColor: 'black'
    },

    contactInfoMobile :{
        marginTop: '5px',
        
        padding: '20px',
          backgroundColor: 'black'
      },

    headerContact :{
        color: 'black',
        fontFamily: 'Anton',
        padding: '15px',
        backgroundColor: 'yellowgreen',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.7,
        margin: '10px'
        
    },

    headerContactMobile :{
        color: 'black',
        fontFamily: 'Anton',
      
        backgroundColor: 'yellowgreen',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.7,
     
        
    },

    contactLists :{
        color: 'yellowgreen',
        fontFamily: 'Anton',
        padding: '5px',
       
        
    },
    contactListsMobile :{
        color: 'yellowgreen',
        fontFamily: 'Anton',
      
       
        
    }
}))
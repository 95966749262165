import React, {useState, useRef} from 'react';
import useStyles from './contactStyles';
import { useTheme } from '@material-ui/styles';
import { useForm, FormProvider } from 'react-hook-form';
import {Grid, Typography, Button, TextField, Card, Snackbar, IconButton, CssBaseline, InputLabel, Select, MenuItem, Box, OutlinedInput, Chip } from '@material-ui/core';

import ContactInfo from './ContactInfo';
import {Close} from '@material-ui/icons'
import emailjs from '@emailjs/browser';




const Contact = () => {
 

let classes=useStyles();


const [open, setOpen] = React.useState(false);



 const handleClose = (event, reason) => {
 if (reason === 'clickaway') {
   return;
 }

 setOpen(false);
};

const action = (
 <React.Fragment>
 
   <IconButton
     size="small"
     aria-label="close"
     color="inherit"
     onClick={handleClose}
   >
     <Close fontSize="small" />
   </IconButton>
 </React.Fragment>
);

const { register } = useForm();


 const form = useRef();

 const sendEmail = (e) => {
   e.preventDefault();
   
   emailjs.sendForm(
     'service_z7as05v', 
   'template_k4ouk7k', 
   form.current, 'Yoyw5S9iZ4qoSwyh2')
     .then((result) => {
       setOpen(true);
   window.location.reload(false)
      
         console.log('success');
     }, (error) => {
     
         console.log(error, 'error not sent');
     });
 };


  return (
    <>
    <CssBaseline />
    <main >
    
    <Grid container className={classes.layout} >
   
    <Grid item xs={12} sm={12} md={6} lg={6} >
      <div className={classes.border}>
<Card style={{backgroundColor: 'black'}}>
    <Typography align='center' variant='h4' className={classes.text}>Contact</Typography> 
  
</Card>
    
 
    <div align='center'>
      <FormProvider> 
      <form   ref={form} id='contact-form' align='center' onSubmit={sendEmail}>
    
     
  <TextField 
  style={{color: 'white'}}
  type='text' 
  name='username' 
  label='Name' 
  className={classes.input} 
  fullWidth
  {...register('username', { required: true })}/>
  <br/>

  <TextField type='email'
   name='user_email' 
  label='Email' 
  className={classes.input} 
  fullWidth
  
  
  {...register('user_email', { required: true })}/>

 

  <TextField
  name='message' 
  label='Write Your Message Here' 
  className={classes.textarea} 
  {...register('message', { required: true })}
  maxLength='1500'
  fullWidth
  multiline
  minRows={6}
  />
  {/* <Typography className={classes.wordCount} >Characters Left: {messageCharsLeft}</Typography>  */}
  <br/>
  <Button type='submit' variant='contained' fullWidth className={classes.button} >Send</Button>
  <Snackbar
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        message="Message Sent"
        action={action}
      /> 
</form>

</FormProvider>
    </div>
 
    </div>
    </Grid> 
    
    <Grid item xs={12} sm={12} md={6} lg={6} >
      <ContactInfo />
      </Grid>
    
    </Grid>

    </main>
    
    </>
  );
}

export default Contact;
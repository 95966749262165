import React, {useState} from 'react'
import { Grid, Typography, Card, Link } from '@material-ui/core';

import myFile from '../../pdf/resume.pdf';
import useStyles from './PortfolioStyles'



const Resume = () => {
  let classes = useStyles(); 
 

  return (
    <>
    <Grid container> 
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card className={classes.resumeCard}>
        <Typography variant='h4' className={classes.font} style={{color: 'yellowgreen'}}>Resume</Typography>
        <Link href={myFile} target='_blank' className={classes.resume}>Resume Link</Link>
        </Card>
    </Grid>

      
        </Grid>
        </>
  )
}

export default Resume;

import React, { useEffect } from 'react';
import {Divider, Grid, Typography, Card, CardMedia, Button, useMediaQuery, useTheme} from '@material-ui/core';
import logo from '../assets/shanCodesPurpleSmoke.png';
import  Aos from 'aos';
import 'aos/dist/aos.css';
import useStyles from './HomeStyles';
import Portfolio from './Portfolio/Portfolio';
import Contact from './Contact/Contact';
import About from './About/About';
import PortfolioMobile from './Portfolio/PortfolioMobile';
import softwareDevelopmentSkills from '../assets/devSkills.png';
import animSkills from '../assets/animSkills.png';
import designSkills from '../assets/designSkills.png';
import LogoText from './Text/Text';
import ShanCodesBanner from '../assets/ShanCodesBanner.png';
import ShanCodesBannerMobile from '../assets/shanCodesMobile.png';

const Home = () => {
let classes = useStyles(); 
let theme=useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(()=>{
Aos.init({duration: 2000})
  }, [])
  return (
    <>
      <main >
      <Grid container >
        {isMobile ? (
           <Grid item xs={12} sm={12} md={12} lg={12} >
           
           <CardMedia component='img' alt='shancodes banner' src={ShanCodesBannerMobile} className={classes.banner} />
            
                     </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} >
           
          <CardMedia component='img' alt='shancodes banner' src={ShanCodesBanner} className={classes.banner} />
           
                    </Grid>
        )}
     
          </Grid>

          <Grid container className={classes.skillsLayout} id='about' >
  <Grid  item xs={12} sm={12} md={12} lg={12}>
 
   <About />
   </Grid>
  </Grid>
 <div id='portfolio' >
    {isMobile ? (
    <Grid container className={classes.portfolioMobileBg} >

    <Grid  item xs={12} sm={12} md={12} lg={12}>
      <PortfolioMobile />
      </Grid>
      </Grid>
    ) : (
      <Grid container className={classes.cardOne} >

      <Grid  item xs={12} sm={12} md={12} lg={12}> 
 <Portfolio /> 
 </Grid>
 </Grid>
    )}
  </div>

          <Grid container className={classes.skillsLayout} id='services'>
          {isMobile ? (
             <Grid item xs={12} sm={12} md={12} lg={12} >
             <Typography align='center' variant= 'h3' className={classes.fontServiceMobile}>Services</Typography>
             </Grid> 
             
          ) : (
             <Grid item xs={12} sm={12} md={12} lg={12} >
            <Typography align='center' variant= 'h2' className={classes.fontService}>Services</Typography>
            </Grid> 
          )}
          
          
    <Grid item xs={12} sm={12} md={4} lg={4} >
    
     <div align='center' >
       
         <Typography variant='h4' align='center' style={{color: 'rgb(236,167,255)'}} className={classes.font}>Software</Typography>
         <Typography variant='h4' align='center' style={{color: 'rgb(236,167,255)'}} className={classes.font}>Development</Typography>
         <div align='center' style={{width: 'auto',}}>
         <CardMedia component='img' align='center' src={softwareDevelopmentSkills} alt='Shan Codes Neon Logo' className={classes.skills} />
         </div>
        
        
     </div>
  
    </Grid>
    <Grid item xs={12} sm={12} md={4} lg={4}>
    
    <div  align='center'>
      
        <Typography variant='h4' align='center' style={{color: 'lightgreen',}} className={classes.font}>Graphic</Typography>
        <Typography variant='h4' align='center' style={{color: 'lightgreen',}} className={classes.font}>Design</Typography>
        <div align='center' style={{width: 'auto'}}>
        <CardMedia component='img' src={designSkills} alt='Shan Codes Neon Logo' className={classes.skills}/>
        </div>
 
    </div>
 
   </Grid>
   <Grid item xs={12} sm={12} md={4} lg={4}>
    
    <div  align='center'>
      
        <Typography align='center' variant='h4' style={{color: 'lightpink',}} className={classes.font}>Animations</Typography>
        <Typography align='center' variant='h4' style={{color: 'lightpink',}} className={classes.font}>& 3D Models</Typography>
        <div align='center' style={{width: 'auto'}}>
        <CardMedia component='img' src={animSkills} alt='Shan Codes Neon Logo'  className={classes.skills}/>
        </div>
     
    </div>
 
   </Grid>
  </Grid>

  <div id='contact'>
{isMobile ? (
  <Grid container className={classes.contactMobileBg}>
<Grid  item xs={12} sm={12} md={12} lg={12}>
    <Contact />
    </Grid>
 </Grid>
) : (
  <Grid container className={classes.cardThree}>
  <Grid  item xs={12} sm={12} md={12} lg={12}>
      <Contact />
      </Grid>
   </Grid>
)}

</div>
      
      
      </main>
      </>
  )
}

export default Home
import React from 'react';
import {CssBaseline, Typography, Paper} from '@material-ui/core';
import backgroundImage from './assets/backgroundImage.jpg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import {NavBar, Home, Footer} from './components';

function App() {



  return (
    <>
    <Router>
    <CssBaseline />
    
        <main >
        
       
          <div id='container' ></div>
          
    <Paper style={{backgroundColor: 'black'}}>
 <NavBar />
 <Home />
<Footer />    
  
    </Paper>
    </main>
    </Router>
    </>
  );
}

export default App;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme)=>({


text: {
    fontFamily: 'Anton',
    color: 'rgb(139,75,157)'
},



footer: {
   
    paddingBottom: '10px',
    paddingTop: '10px',
    
},

footerLink:  {
    color: 'rgb(139,75,157)',
    fontWeight: 'bold'

}

}))
import React from 'react';
import { Paper, Grid,  Typography, Link} from '@material-ui/core';
import { Copyright } from '@material-ui/icons';


import useStyles from './FooterStyles';
import { useNavigate } from 'react-router-dom';


const Footer = () => {
let classes = useStyles(); 
  return (
    <>
    
       <Grid container className={classes.footer}>
       <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography align='center' className={classes.text}> 
          <Copyright style={{color: 'yellowgreen', padding: '5px' }} />
         2022 -
        
         Designed & Built by 
          ShanCodes
         </Typography>
      
            </Grid>
         
       
       </Grid>
    
    </>
  )
}

export default Footer;
import { Typography, Card, Divider, Grid, useTheme, useMediaQuery, Link, List, ListItem } from '@material-ui/core'
import React from 'react';
import useStyles from './contactStyles';
import {useNavigate} from 'react-router-dom';

const ContactInfo = () => {
  let classes = useStyles(); 
  let theme=useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  let navigate = useNavigate();

  const onHomeClicked=()=>{
    let path = `/`; 
    navigate(path);
    window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
       });
}
  return (
    <>
    {isMobile ? (
      <Card className={classes.contactInfoMobile}>
        <Typography variant='subtitle1' align='center' className={classes.contactLists}>Do you need a website? Do you have opportunites for me? Or do you just want to talk tech? Let's connect!</Typography>
        <Divider style={{backgroundColor: 'yellowgreen'}} />
        <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography align='center' className={classes.headerContactMobile} >Follow me!</Typography>
        <List>
          <ListItem>
        <Link align='center' href='https://github.com/shkramer1' target='_blank' className={classes.contactListsMobile}>Github</Link>
        </ListItem>
        <ListItem>
        <Link align='center' href='https://twitter.com/shancodes' target='_blank' className={classes.contactListsMobile}>Twitter</Link>
        </ListItem>
        <ListItem>
        <Link align='center' href='https://www.linkedin.com/in/shannon-kramer-747966b5/' target='_blank' className={classes.contactListsMobile}>LinkedIn</Link>
       </ListItem>
       <ListItem>
        <Link align='center' href='https://www.upwork.com/freelancers/~0157c5fe41221f8983?viewMode=1' target='_blank' className={classes.contactListsMobile}>Upwork</Link>
       </ListItem> 
       </List>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography align='center' className={classes.headerContactMobile}>Quick Links</Typography>
        <List>
        <ListItem>
        <Link align='center'  onClick={onHomeClicked} className={classes.contactListsMobile}>Home</Link>
        </ListItem>
        <ListItem>
        <Link align='center'  onClick={(e) => {e.preventDefault(); window.location.replace('/#about');}} className={classes.contactListsMobile}>About</Link>
        </ListItem>
        <ListItem>
        <Link align='center'  onClick={(e) => {e.preventDefault(); window.location.replace('/#portfolio');}} className={classes.contactListsMobile}>Portfolio</Link>
        </ListItem>
        <ListItem>
        <Link align='center'  onClick={(e) => {e.preventDefault(); window.location.replace('/#services');}} className={classes.contactListsMobile}>Services</Link>
        </ListItem>
        <ListItem>
        <Link align='center'  onClick={(e) => {e.preventDefault(); window.location.replace('/#contact');}} className={classes.contactListsMobile}>Contact</Link>
        </ListItem>
        </List>
        </Grid>
        </Grid>
    </Card>

    ) : (
<Card className={classes.contactInfo}>
        <Typography variant='h6' align='center' className={classes.contactLists}>Do you need a website? Do you have opportunites for me? Or do you just want to talk tech? Let's connect!</Typography>
        <Divider style={{backgroundColor: 'yellowgreen'}} />
        <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography align='center' className={classes.headerContact} >Follow me!</Typography>
        <List >
          <ListItem >
        <Link align='center' href='https://github.com/shkramer1' target='_blank' className={classes.contactLists}>Github</Link>
        </ListItem>
        <ListItem>
        <Link align='center' href='https://twitter.com/shancodes' target='_blank' className={classes.contactLists}>Twitter</Link>
        </ListItem>
        <ListItem>
        <Link align='center' href='https://www.linkedin.com/in/shannon-kramer-747966b5/' target='_blank' className={classes.contactLists}>LinkedIn</Link>
       </ListItem>
       <ListItem>
        <Link align='center' href='https://www.upwork.com/freelancers/~0157c5fe41221f8983?viewMode=1' target='_blank' className={classes.contactLists}>Upwork</Link>
       </ListItem> 
       </List>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography align='center' className={classes.headerContact}>Quick Links</Typography>
        <List>
        <ListItem>
        <Link align='center'  onClick={onHomeClicked} className={classes.contactLists}>Home</Link>
        </ListItem>
        <ListItem>
        <Link align='center'  onClick={(e) => {e.preventDefault(); window.location.replace('/#about');}} className={classes.contactLists}>About</Link>
        </ListItem>
        <ListItem>
        <Link align='center'  onClick={(e) => {e.preventDefault(); window.location.replace('/#portfolio');}} className={classes.contactLists}>Portfolio</Link>
        </ListItem>
        <ListItem>
        <Link align='center'  onClick={(e) => {e.preventDefault(); window.location.replace('/#services');}} className={classes.contactLists}>Services</Link>
        </ListItem>
        <ListItem>
        <Link align='center'  onClick={(e) => {e.preventDefault(); window.location.replace('/#contact');}} className={classes.contactLists}>Contact</Link>
        </ListItem>
        </List>
        </Grid>
        </Grid>
    </Card>

    )}
    
    
    </>
  )
}

export default ContactInfo
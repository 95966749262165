import React from 'react'
import {Paper, Typography, Button, CardMedia, Card, Grid} from '@material-ui/core';

import useStyles from './PortfolioStyles';

const Github = () => {
    let classes = useStyles() ;
  return (
    <>
   
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>

            
     
             
                <Typography variant='h4' className={classes.githubHeader}>Github</Typography>  
            <Button href='https://github.com/shkramer1' target='_blank' component='a'>
                
                 <Typography variant='body2' className={classes.githubButton}>https://github.com/shkramer1</Typography> 
            </Button>
            
       
        
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} lg={6}>
        <Card className={classes.githubExamples}>  
                <Typography variant='subtitle1' className={classes.tabs}>E-Commerce Website Cart Component</Typography>  
            <Button href='https://github.com/shkramer1' target='_blank' component='a'>
                
                 <Typography variant='body2' className={classes.tabInfo}>Github Repository</Typography> 
            </Button>
            <Button  href='https://github.com/shkramer1' target='_blank' component='a'>
                
                <Typography   variant='body2' className={classes.tabInfo}>E-Commerce Website</Typography> 
           </Button>
           
            
        </Card>
        </Grid> */}

        <Grid item xs={12} sm={12} md={6} lg={6}>
        <Card className={classes.githubExamples}>  
                <Typography variant='subtitle1' className={classes.tabs}>Contact Form using Email.js</Typography>  
               <Button href='https://github.com/shkramer1/Contact-Form-Using-Email.js' target='_blank' component='a'>
                
                 <Typography variant='body2' className={classes.tabInfo}>Github Repository</Typography> 
            </Button>
            <Button   href='https://contact-form-shancodes.netlify.app/' target='_blank' component='a'>
                
                <Typography variant='body2' className={classes.tabInfo}>Website Demo</Typography> 
           </Button>
           
            
        </Card>
</Grid>

<Grid item xs={12} sm={12} md={6} lg={6}>
        <Card className={classes.githubExamples}>  
                <Typography variant='subtitle1' className={classes.tabs}>Drawer Feature for Mobile Compatability</Typography>  
            <Button href='https://github.com/shkramer1/drawer-component' target='_blank' component='a'>
                
                 <Typography variant='body2' className={classes.tabInfo}>Github Repository</Typography> 
            </Button>
            <Button   href='https://drawer-component-shancodes.netlify.app/' target='_blank' component='a'>
                
                <Typography variant='body2' className={classes.tabInfo}>Website Demo</Typography> 
           </Button>
         
        </Card>
</Grid>

<Grid item xs={12} sm={12} md={12} lg={12}>
        <Card className={classes.githubExamples}>  
                <Typography variant='subtitle1' className={classes.tabs}>3D Model Using Three.js & three-react-fiber</Typography>  
            <Button href='https://github.com/shkramer1/react-threejs-cubes' target='_blank' component='a'>
                
                 <Typography variant='body2' className={classes.tabInfo}>Github Repository</Typography> 
            </Button>
            <Button   href='https://3d-cubes-shan-codes.netlify.app/' target='_blank' component='a'>
                
                <Typography variant='body2' className={classes.tabInfo}>Website Demo</Typography> 
           </Button>
         
        </Card>
        </Grid>
  </Grid>
  
    </>
  )
}

export default Github
import React from 'react'
import { Typography, CardMedia, Button, Grid } from '@material-ui/core'

import useStyles from './PortfolioStyles';
import TwitterIcon from '../../assets/twitterTab.png';
import { TwitterTimelineEmbed, TwitterTweetEmbed, TwitterFollowButton } from 'react-twitter-embed';

const TwitterTab = () => {
  let classes = useStyles() ;
  return (
    <>

        <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>

           <div>
        <img src={TwitterIcon} alt='twitter logo' height='80px'/>
            
            </div>
            <div>
            <Button className={classes.twitterButton}>@shancodes</Button>
        </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>

        

        
     
          
        <div>
            <Typography className={classes.twitterButton}>
              Check out my twitter to follow my coding journey through short blurbs, pictures, & retweets!
              </Typography>
              <TwitterFollowButton
      onLoad={function noRefCheck(){}}
      screenName="shancodes"
      options={{
        size: 'large'
      }}
    />
        </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <div>
        <TwitterTweetEmbed
  tweetId={'1536480434351411200'}
  theme='dark'
  height='100px'
/>
        </div>
</Grid>

      
        </Grid>
   
    </>
  )
}

export default TwitterTab
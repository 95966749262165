import { makeStyles } from "@material-ui/styles";

export default makeStyles ((theme) =>({

    drawerLink:{
        textDecoration:"none",
        color: "yellowgreen",
        fontFamily: 'Anton',
        fontSize: '20px'
        
    },

    portfolioLink:{
        textDecoration:"none",
        color: "yellowgreen",
        fontFamily: 'Anton',
        fontSize: '15px'
        
    },
    drawerHeader: {
        textDecoration:"none",
        color: "yellowgreen",
        fontFamily: 'Nosifer',
      
        margin: '15px'
    }, 

    icon:{
        color: "rgb(248,0,89)"
    },

    primary :{
        color: '#00DEC1'
      },
     secondary: {
       color: "rgb(248,0,89)"
     },

     drawer: {
         backgroundColor: 'black'
     },


     

})
)
import React from 'react';
import {Grid, Typography, Box, useTheme, useMediaQuery, Card, Divider} from '@material-ui/core';
import useStyles from '../HomeStyles';
import Avatar from '../3dModel/Avatar';
import shan from '../../assets/shanPic.png';
import { Link } from 'react-router-dom';
import {FavoriteBorder, Lens} from '@material-ui/icons';


const About = () => {
    let classes=useStyles();
    let theme=useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
    
   <Grid container >
    {isMobile ? (
         <Grid item xs={12} sm={12} md={12} lg={12}>
         <Typography align='center' variant = 'h5' className={classes.font2} style={{color: 'white'}} >
           Welcome to Shan Codes! 
        </Typography>
        <Typography align='center' variant = 'h6' className={classes.font2} style={{color: 'white'}} >
        FrontEnd Developer and UI/UX Designer 
     </Typography>
        <div align='center'>
    <img src={shan} alt='shan pic' height='300px'/>
    </div>
        </Grid>
    ) : (
      <Grid item xs={12} sm={12} md={12} lg={12}>
      <Typography align='center' variant = 'h3' className={classes.font2} style={{color: 'white'}} >
        Welcome to Shan Codes! 
     </Typography>
     <Typography align='center' variant = 'h5' className={classes.font2} style={{color: 'white'}} >
        FrontEnd Developer and UI/UX Designer 
     </Typography>
    
     </Grid>
    )}

    {isMobile ? (null) : (
      <Grid item xs={12} sm={12} md={12} lg={12}>
 <div align='center'>
 <img src={shan} alt='shan pic' height='300px'/>
 </div>
 </Grid>

    )}
      <Grid item xs={12} sm={12} md={12} lg={12}>

<Divider variant='middle' style={{backgroundColor: 'yellowgreen', marginTop: '50px'}} />
</Grid>
    {isMobile ? (
      <Grid item xs={12} sm={12} md={7} lg={7}>
      
    
      <Grid item xs={12} sm={12} md={12} lg={12}>
      <Typography align='center' variant = 'h4' className={classes.font2} style={{color: 'white', margin: '30px'}} >
        About
     </Typography>
     </Grid>
     <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card align='center' style={{backgroundColor: 'black'}}>
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
 
    </Card>
    </Grid>
    
    
     
     <div className={classes.bioBgMobile}>
   
   
    <Typography align='center'  className={classes.font2} style={{color: 'white'}} >
     I have curated this page to embody Shan in her 
    ideal coding space.  While working, I become a cyber genius who can bend the intricacies of the web into anything I want. 
    I transport into a futuristic cyber world where I am the master. </Typography> 

    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card align='center' style={{backgroundColor: 'black'}}>
    <FavoriteBorder className={classes.icon}  />
    
    </Card>
    </Grid>

    <Typography align='center'className={classes.font2} style={{color: 'white'}} >
    I’m always expanding my knowledge and learning new programming languages and technologies to improve my work.  
    Everything that you see on this website was created by me, Shannon!  The graphics, 3D models, site, everything!  
    I love learning new skills and using them to create beautiful web pages 
    that showcase the newest technologies! Watching my code transform a blank page into something awesome is my ultimate high. 
    </Typography>

    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card align='center' style={{backgroundColor: 'black'}}>
    <FavoriteBorder className={classes.icon}  />
    
    </Card>
    </Grid>

    <Typography align='center'  className={classes.font2} style={{color: 'white'}} >
    I am skilled in ReactJS, UI/UX design, and Graphic design. 
     I’m always looking to improve my skills so I'm currently learning Solidity and Blender.  
     I develop and design company websites, blogs, and e-commerce stores.  
    </Typography>

    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card align='center' style={{backgroundColor: 'black'}}>
    <FavoriteBorder className={classes.icon}  />
    
    </Card>
    </Grid>

    <Typography align='center' className={classes.font2} style={{color: 'white'}} >
      Please contact me with any questions 
      <Link to='/' className={classes.font2} style={{color: 'white'}}>here!</Link>
       :)
    </Typography>
    
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card align='center' style={{backgroundColor: 'black'}}>
    <FavoriteBorder className={classes.icon}  />
    
    </Card>
    </Grid>

    <Typography align='center'  className={classes.font2} style={{color: 'white'}} >
      Sincerely,
    </Typography>
    <Typography align='center'  className={classes.font2} style={{color: 'white'}} >
    Shannon 'Shan' Kramer
    </Typography>
   
    </div>
 
    </Grid>
    ) : (
      <Grid item xs={12} sm={12} md={7} lg={7}>
     
    
      
     <Card className={classes.bioBg}>
   
     <Grid item xs={12} sm={12} md={12} lg={12}>
      <Typography align='center' variant = 'h3' className={classes.font2} style={{color: 'white'}} >
        About
     </Typography>
     <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card align='center' style={{backgroundColor: 'black'}}>
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    <Lens className={classes.icon}  />
    </Card>
    </Grid>
    
    
     </Grid>
    <Typography align='center' variant='h6' className={classes.font2} style={{color: 'white', lineHeight: '40px'}} >
     I have curated this page to embody Shan in her 
    ideal coding space.  While working, I become a cyber genius who can bend the intricacies of the web into anything I want. 
    I transport into a futuristic cyber world where I am the master. </Typography> 

    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card align='center' style={{backgroundColor: 'black'}}>
    <FavoriteBorder className={classes.icon}  />
    
    </Card>
    </Grid>

    <Typography variant='h6' align='center'className={classes.font2} style={{color: 'white', lineHeight: '40px'}} >
    I’m always expanding my knowledge and learning new programming languages and technologies to improve my work.  
    Everything that you see on this website was created by me, Shannon!  The graphics, 3D models, site, everything!  
    I love learning new skills and using them to create beautiful web pages 
    that showcase the newest technologies! Watching my code transform a blank page into something awesome is my ultimate high. 
    </Typography>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card align='center' style={{backgroundColor: 'black'}}>
    <FavoriteBorder className={classes.icon}  />
    
    </Card>
    </Grid>
    <Typography align='center' variant='h6' className={classes.font2} style={{color: 'white', lineHeight: '40px'}} >
    I am skilled in ReactJS, UI/UX design, and Graphic design. 
     I’m always looking to improve my skills so I'm currently learning Solidity and Blender.  
     I develop and design company websites, blogs, and e-commerce stores.  
    </Typography>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card align='center' style={{backgroundColor: 'black'}}>
    <FavoriteBorder className={classes.icon}  />
    
    </Card>
    </Grid>
   
    <Typography align='center' variant='h6'  className={classes.font2} style={{color: 'white', lineHeight: '40px'}} >
      Sincerely,
    </Typography>
    <Typography align='center' variant='h6' className={classes.font2} style={{color: 'white', lineHeight: '40px'}} >
    Shannon 'Shan' Kramer
    </Typography>
   
    </Card>
    
    </Grid>
    ) }

    
  
    <Grid item xs={12} sm={12} md={5} lg={5}>

      {isMobile ? (
        <div className={classes.avatarMobile}>
     
     
     <Avatar/> 
     <div  style={{ border: 'solid 5px', borderColor: 'yellowgreen',}}>
     <Typography variant='h6' style={{color: 'yellowgreen', backgroundColor: 'black'}} className={classes.font} align='center'>
         Shannon 'Shan' Kramer
         </Typography>
     <Typography variant='subtitle1' style={{color: 'yellowgreen',  backgroundColor: 'black'}} className={classes.font} align='center'>@shancodes</Typography>
   </div>
   </div>
      ) : (
<div className={classes.avatar}>
     
     
     <Avatar/> 
     <div  style={{ border: 'solid 5px', borderColor: 'yellowgreen',}}>
     <Typography variant='h4' style={{color: 'yellowgreen', backgroundColor: 'black'}} className={classes.font} align='center'>
         Shannon 'Shan' Kramer
         </Typography>
     <Typography variant='h6' style={{color: 'yellowgreen',  backgroundColor: 'black'}} className={classes.font} align='center'>@shancodes</Typography>
   </div>
   </div>

      )}
    
 
    </Grid>

  
    </Grid> 
    
    </>
  )
}

export default About
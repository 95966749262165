import React, {Suspense, useRef} from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';

function Text({ ...props }) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/shanCodesText.glb')
    return (
      <group ref={group} {...props} dispose={null}>
        <group position={[0, -0.01, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={1}>
          <mesh geometry={nodes['3D_Text_-_S'].geometry} material={materials.Godzilla} position={[-146.64, 8.5, 6.01]} />
          <mesh geometry={nodes['3D_Text_-_h'].geometry} material={materials.Godzilla} position={[-115.29, 8.5, 6.01]} />
          <mesh geometry={nodes['3D_Text_-_a'].geometry} material={materials.Godzilla} position={[-84.44, 8.5, 6.01]} />
          <mesh geometry={nodes['3D_Text_-_n'].geometry} material={materials.Godzilla} position={[-52.69, 8.5, 6.01]} />
          <mesh geometry={nodes['3D_Text_-_C'].geometry} material={materials.Godzilla} position={[-9.09, 8.5, 6.01]} />
          <mesh geometry={nodes['3D_Text_-_o'].geometry} material={materials.Godzilla} position={[24.74, 8.5, 6.01]} />
          <mesh geometry={nodes['3D_Text_-_d'].geometry} material={materials.Godzilla} position={[56.23, 8.5, 6.01]} />
          <mesh geometry={nodes['3D_Text_-_e'].geometry} material={materials.Godzilla} position={[88, 8.5, 6.01]} />
          <mesh geometry={nodes['3D_Text_-_s'].geometry} material={materials.Godzilla} position={[117.05, 8.5, 6.01]} />
        </group>
      </group>
    )
  }

  useGLTF.preload('/shanCodesText.glb')

  

const LogoText = () => {
  return (
    <>
     <Canvas camera={{fov: 65, position: [0,.3,75]}} >
        <Suspense fallback={null}>
    <ambientLight />
    <directionalLight intensity={1} position={[0,0,50]} />
    <Text />
    <OrbitControls enablePan={true} enableRotate={true} enableZoom={true} />
        </Suspense>
        </Canvas>   

    </>
  )
}

export default LogoText;
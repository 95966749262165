import React from 'react'
import {Card, Typography, Grid, useTheme, useMediaQuery} from '@material-ui/core';
import useStyles from './PortfolioStyles';

const Skills = () => {
    let classes= useStyles(); 
    let theme=useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
   
   <>
      

    
        
        <Grid container style={{border: 'solid 5px', borderColor: 'yellowgreen'}}>
          
        <Grid item xs={12} sm={12} md={12} lg={12}>

            
            <Typography 
          className={classes.skillHeader} variant= 'h4'> Skills </Typography>
        
</Grid>


  <Grid item xs={6} sm={6} md={6} lg={6}>
  
  
  <Typography  className={classes.tabHeader}> Tech </Typography>
     <Card className={classes.skills}>
         <Typography className={classes.tabInfo}> React </Typography>
            <Typography className={classes.tabInfo}> Javascript </Typography>
            <Typography className={classes.tabInfo}> Material UI </Typography>
            <Typography className={classes.tabInfo}> Bootstrap V5 </Typography>
            
            <Typography className={classes.tabInfo}> Postman </Typography>
            <Typography className={classes.tabInfo}> Blender </Typography>
           
   
        </Card>
</Grid>
{isMobile ? (



<Grid item xs={6} sm={6} md={6} lg={6}>

 
            <Typography  className={classes.tabHeader}> Interpersonal </Typography>
         <Card className={classes.skills}>   
            <Typography variant= 'body2'  className={classes.tabInfo}> Adaptable </Typography>
            <Typography variant= 'body2'  className={classes.tabInfo}> Quick Learner </Typography>
            <Typography variant= 'body2'  className={classes.tabInfo}> Time Management </Typography>
            <Typography variant= 'body2'  className={classes.tabInfo}> Problem-Solving </Typography>
            <Typography variant= 'body2'  className={classes.tabInfo}> Positive Personality </Typography>
            
            
        </Card>
</Grid>
) : (



<Grid item xs={6} sm={6} md={6} lg={6}>

 
            <Typography  className={classes.tabHeader}> Interpersonal </Typography>
         <Card className={classes.skills} >   
            <Typography className={classes.tabInfo}> Adaptable </Typography>
            <Typography className={classes.tabInfo}> Quick Learner </Typography>
            <Typography className={classes.tabInfo}> Time Management </Typography>
            <Typography className={classes.tabInfo}> Problem-Solving </Typography>
            <Typography className={classes.tabInfo}> Positive Personality </Typography>
            <Typography className={classes.tabInfo}> Dependability </Typography>
            
        </Card>
</Grid>

)}





        </Grid>
      
   
    
    </>
  )
}

export default Skills